import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getIntegrationTemplates } from 'app/entities/integration-template/integration-template.reducer';
import { getEntities as getDataCategories } from 'app/entities/data-category/data-category.reducer';
import { getEntities as getIntegrationChannels } from 'app/entities/integration-channel/integration-channel.reducer';
import { createEntity, getEntity, reset, updateEntity } from './integration-template-category.reducer';

export const IntegrationTemplateCategoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const integrationTemplates = useAppSelector(state => state.integrationTemplate.entities);
  const dataCategories = useAppSelector(state => state.dataCategory.entities);
  const integrationChannels = useAppSelector(state => state.integrationChannel.entities);
  const integrationTemplateCategoryEntity = useAppSelector(state => state.integrationTemplateCategory.entity);
  const loading = useAppSelector(state => state.integrationTemplateCategory.loading);
  const updating = useAppSelector(state => state.integrationTemplateCategory.updating);
  const updateSuccess = useAppSelector(state => state.integrationTemplateCategory.updateSuccess);

  const handleClose = () => {
    navigate(`/integration-template-category${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getIntegrationTemplates({}));
    dispatch(getDataCategories({}));
    dispatch(getIntegrationChannels({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }

    const entity = {
      ...integrationTemplateCategoryEntity,
      ...values,
      template: integrationTemplates.find(it => it.id.toString() === values.template?.toString()),
      category: dataCategories.find(it => it.id.toString() === values.category?.toString()),
      channel: integrationChannels.find(it => it.id.toString() === values.channel?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...integrationTemplateCategoryEntity,
          template: integrationTemplateCategoryEntity?.template?.id,
          category: integrationTemplateCategoryEntity?.category?.id,
          channel: integrationTemplateCategoryEntity?.channel?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="tinyMedicRestApp.integrationTemplateCategory.home.createOrEditLabel"
            data-cy="IntegrationTemplateCategoryCreateUpdateHeading"
          >
            <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.home.createOrEditLabel">
              Create or edit a IntegrationTemplateCategory
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="integration-template-category-id"
                  label={translate('tinyMedicRestApp.integrationTemplateCategory.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.integrationTemplateCategory.active')}
                id="integration-template-category-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="activeLabel">
                <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.help.active" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.integrationTemplateCategory.cursor')}
                id="integration-template-category-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <UncontrolledTooltip target="cursorLabel">
                <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.help.cursor" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.integrationTemplateCategory.lastUpdated')}
                id="integration-template-category-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
              />
              <UncontrolledTooltip target="lastUpdatedLabel">
                <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.help.lastUpdated" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.integrationTemplateCategory.microSec')}
                id="integration-template-category-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <UncontrolledTooltip target="microSecLabel">
                <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.help.microSec" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.integrationTemplateCategory.timestampEntry')}
                id="integration-template-category-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
              />
              <UncontrolledTooltip target="timestampEntryLabel">
                <Translate contentKey="tinyMedicRestApp.integrationTemplateCategory.help.timestampEntry" />
              </UncontrolledTooltip>
              <ValidatedField
                id="integration-template-category-template"
                name="template"
                data-cy="template"
                label={translate('tinyMedicRestApp.integrationTemplateCategory.template')}
                type="select"
              >
                <option value="" key="0" />
                {integrationTemplates
                  ? integrationTemplates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="integration-template-category-category"
                name="category"
                data-cy="category"
                label={translate('tinyMedicRestApp.integrationTemplateCategory.category')}
                type="select"
              >
                <option value="" key="0" />
                {dataCategories
                  ? dataCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="integration-template-category-channel"
                name="channel"
                data-cy="channel"
                label={translate('tinyMedicRestApp.integrationTemplateCategory.channel')}
                type="select"
              >
                <option value="" key="0" />
                {integrationChannels
                  ? integrationChannels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/integration-template-category"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IntegrationTemplateCategoryUpdate;
