import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './package-subscription-discount.reducer';

export const PackageSubscriptionDiscountDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packageSubscriptionDiscountEntity = useAppSelector(state => state.packageSubscriptionDiscount.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packageSubscriptionDiscountDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.detail.title">PackageSubscriptionDiscount</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.id">Id</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.id}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.discount">Discount</Translate>
            </span>
            <UncontrolledTooltip target="discount">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.help.discount" />
            </UncontrolledTooltip>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.discount}</dd>
          <dt>
            <span id="actualDiscount">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.actualDiscount">Actual Discount</Translate>
            </span>
            <UncontrolledTooltip target="actualDiscount">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.help.actualDiscount" />
            </UncontrolledTooltip>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.actualDiscount}</dd>
          <dt>
            <span id="billNumber">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.billNumber">Bill Number</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.billNumber}</dd>
          <dt>
            <span id="billDate">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.billDate">Bill Date</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.billDate}</dd>
          <dt>
            <span id="billType">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.billType">Bill Type</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.billType}</dd>
          <dt>
            <span id="billId">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.billId">Bill Id</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.billId}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.active">Active</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.timestampEntry}</dd>
          <dt>
            <span id="remarks">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.remarks">Remarks</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.remarks}</dd>
          <dt>
            <span id="exception">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.exception">Exception</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.exception ? 'true' : 'false'}</dd>
          <dt>
            <span id="billOrigin">
              <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.billOrigin">Bill Origin</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.billOrigin}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.usedBy">Used By</Translate>
          </dt>
          <dd>{packageSubscriptionDiscountEntity.usedBy ? packageSubscriptionDiscountEntity.usedBy.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/package-subscription-discount" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/package-subscription-discount/${packageSubscriptionDiscountEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackageSubscriptionDiscountDetail;
