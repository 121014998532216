import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { IDataCategory } from 'app/shared/model/data-category.model';

export interface IIntegrationChannel {
  id?: string;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  cVersion?: string | null;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  channelType?: IClinicalDictionary | null;
  categories?: IDataCategory[] | null;
}

export const defaultValue: Readonly<IIntegrationChannel> = {
  active: false,
};
