import { IIntegrationChannel } from 'app/shared/model/integration-channel.model';
import { ITenantIntegrationEndpointAttribute } from 'app/shared/model/tenant-integration-endpoint-attribute.model';
import { ITenantIntegrationTemplate } from 'app/shared/model/tenant-integration-template.model';
import { IDataCategory } from 'app/shared/model/data-category.model';

export interface ITenantIntegrationChannel {
  id?: string;
  localName?: string | null;
  localCode?: string | null;
  tenantId?: string | null;
  tenantType?: string | null;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  channel?: IIntegrationChannel | null;
  attribute?: ITenantIntegrationEndpointAttribute | null;
  template?: ITenantIntegrationTemplate | null;
  categories?: IDataCategory[] | null;
}

export const defaultValue: Readonly<ITenantIntegrationChannel> = {
  active: false,
};
