import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './package-subscriber.reducer';

export const PackageSubscriberDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packageSubscriberEntity = useAppSelector(state => state.packageSubscriber.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packageSubscriberDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.packageSubscriber.detail.title">PackageSubscriber</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.id">Id</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.id}</dd>
          <dt>
            <span id="primaryUser">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.primaryUser">Primary User</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.primaryUser ? 'true' : 'false'}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.active">Active</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.timestampEntry}</dd>
          <dt>
            <span id="subscriberNumber">
              <Translate contentKey="tinyMedicRestApp.packageSubscriber.subscriberNumber">Subscriber Number</Translate>
            </span>
          </dt>
          <dd>{packageSubscriberEntity.subscriberNumber}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscriber.scubscriber">Scubscriber</Translate>
          </dt>
          <dd>{packageSubscriberEntity.scubscriber ? packageSubscriberEntity.scubscriber.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscriber.relationToPrimary">Relation To Primary</Translate>
          </dt>
          <dd>{packageSubscriberEntity.relationToPrimary ? packageSubscriberEntity.relationToPrimary.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscriber.subscription">Subscription</Translate>
          </dt>
          <dd>{packageSubscriberEntity.subscription ? packageSubscriberEntity.subscription.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/package-subscriber" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/package-subscriber/${packageSubscriberEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackageSubscriberDetail;
