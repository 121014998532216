import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './integration-channel.reducer';

export const IntegrationChannelDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const integrationChannelEntity = useAppSelector(state => state.integrationChannel.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="integrationChannelDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.integrationChannel.detail.title">IntegrationChannel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.id">Id</Translate>
            </span>
            <UncontrolledTooltip target="id">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.id" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.name">Name</Translate>
            </span>
            <UncontrolledTooltip target="name">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.name" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.name}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.code}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.description">Description</Translate>
            </span>
            <UncontrolledTooltip target="description">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.description" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.description}</dd>
          <dt>
            <span id="cVersion">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.cVersion">C Version</Translate>
            </span>
            <UncontrolledTooltip target="cVersion">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.cVersion" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.cVersion}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.cursor">Cursor</Translate>
            </span>
            <UncontrolledTooltip target="cursor">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.cursor" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.lastUpdated">Last Updated</Translate>
            </span>
            <UncontrolledTooltip target="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.lastUpdated" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.microSec">Micro Sec</Translate>
            </span>
            <UncontrolledTooltip target="microSec">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.microSec" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.timestampEntry">Timestamp Entry</Translate>
            </span>
            <UncontrolledTooltip target="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.integrationChannel.help.timestampEntry" />
            </UncontrolledTooltip>
          </dt>
          <dd>{integrationChannelEntity.timestampEntry}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.integrationChannel.channelType">Channel Type</Translate>
          </dt>
          <dd>{integrationChannelEntity.channelType ? integrationChannelEntity.channelType.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.integrationChannel.category">Category</Translate>
          </dt>
          <dd>
            {integrationChannelEntity.categories
              ? integrationChannelEntity.categories.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {integrationChannelEntity.categories && i === integrationChannelEntity.categories.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/integration-channel" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/integration-channel/${integrationChannelEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default IntegrationChannelDetail;
