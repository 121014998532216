import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './package-payment-history.reducer';

export const PackagePaymentHistoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packagePaymentHistoryEntity = useAppSelector(state => state.packagePaymentHistory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packagePaymentHistoryDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.detail.title">PackagePaymentHistory</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.id">Id</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.id}</dd>
          <dt>
            <span id="paymentMethod">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.paymentMethod">Payment Method</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.paymentMethod}</dd>
          <dt>
            <span id="transactionDetails">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.transactionDetails">Transaction Details</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.transactionDetails}</dd>
          <dt>
            <span id="paidAmount">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.paidAmount">Paid Amount</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.paidAmount}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.active">Active</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.cursor}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.timestampentry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.microSec}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.lastupdated}</dd>
          <dt>
            <span id="paidByFirstName">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.paidByFirstName">Paid By First Name</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.paidByFirstName}</dd>
          <dt>
            <span id="paidBySecondName">
              <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.paidBySecondName">Paid By Second Name</Translate>
            </span>
          </dt>
          <dd>{packagePaymentHistoryEntity.paidBySecondName}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.subscription">Subscription</Translate>
          </dt>
          <dd>{packagePaymentHistoryEntity.subscription ? packagePaymentHistoryEntity.subscription.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packagePaymentHistory.paidBy">Paid By</Translate>
          </dt>
          <dd>{packagePaymentHistoryEntity.paidBy ? packagePaymentHistoryEntity.paidBy.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/package-payment-history" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/package-payment-history/${packagePaymentHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackagePaymentHistoryDetail;
