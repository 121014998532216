import { IIntegrationTemplate } from 'app/shared/model/integration-template.model';
import { IDataCategory } from 'app/shared/model/data-category.model';
import { IIntegrationChannel } from 'app/shared/model/integration-channel.model';

export interface IIntegrationTemplateCategory {
  id?: string;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  template?: IIntegrationTemplate | null;
  category?: IDataCategory | null;
  channel?: IIntegrationChannel | null;
}

export const defaultValue: Readonly<IIntegrationTemplateCategory> = {
  active: false,
};
