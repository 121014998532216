import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IntegrationChannel from './integration-channel';
import IntegrationChannelDetail from './integration-channel-detail';
import IntegrationChannelUpdate from './integration-channel-update';
import IntegrationChannelDeleteDialog from './integration-channel-delete-dialog';

const IntegrationChannelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IntegrationChannel />} />
    <Route path="new" element={<IntegrationChannelUpdate />} />
    <Route path=":id">
      <Route index element={<IntegrationChannelDetail />} />
      <Route path="edit" element={<IntegrationChannelUpdate />} />
      <Route path="delete" element={<IntegrationChannelDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IntegrationChannelRoutes;
