import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IntegrationTemplateCategory from './integration-template-category';
import IntegrationTemplateCategoryDetail from './integration-template-category-detail';
import IntegrationTemplateCategoryUpdate from './integration-template-category-update';
import IntegrationTemplateCategoryDeleteDialog from './integration-template-category-delete-dialog';

const IntegrationTemplateCategoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IntegrationTemplateCategory />} />
    <Route path="new" element={<IntegrationTemplateCategoryUpdate />} />
    <Route path=":id">
      <Route index element={<IntegrationTemplateCategoryDetail />} />
      <Route path="edit" element={<IntegrationTemplateCategoryUpdate />} />
      <Route path="delete" element={<IntegrationTemplateCategoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IntegrationTemplateCategoryRoutes;
