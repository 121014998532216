import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPackageSubscribers } from 'app/entities/package-subscriber/package-subscriber.reducer';
import { BillType } from 'app/shared/model/enumerations/bill-type.model';
import { BillOrigin } from 'app/shared/model/enumerations/bill-origin.model';
import { createEntity, getEntity, reset, updateEntity } from './package-subscription-discount.reducer';

export const PackageSubscriptionDiscountUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const packageSubscribers = useAppSelector(state => state.packageSubscriber.entities);
  const packageSubscriptionDiscountEntity = useAppSelector(state => state.packageSubscriptionDiscount.entity);
  const loading = useAppSelector(state => state.packageSubscriptionDiscount.loading);
  const updating = useAppSelector(state => state.packageSubscriptionDiscount.updating);
  const updateSuccess = useAppSelector(state => state.packageSubscriptionDiscount.updateSuccess);
  const billTypeValues = Object.keys(BillType);
  const billOriginValues = Object.keys(BillOrigin);

  const handleClose = () => {
    navigate(`/package-subscription-discount${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPackageSubscribers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }
    if (values.actualDiscount !== undefined && typeof values.actualDiscount !== 'number') {
      values.actualDiscount = Number(values.actualDiscount);
    }
    if (values.billDate !== undefined && typeof values.billDate !== 'number') {
      values.billDate = Number(values.billDate);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }

    const entity = {
      ...packageSubscriptionDiscountEntity,
      ...values,
      usedBy: packageSubscribers.find(it => it.id.toString() === values.usedBy?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          billType: 'OP',
          billOrigin: 'OP',
          ...packageSubscriptionDiscountEntity,
          usedBy: packageSubscriptionDiscountEntity?.usedBy?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="tinyMedicRestApp.packageSubscriptionDiscount.home.createOrEditLabel"
            data-cy="PackageSubscriptionDiscountCreateUpdateHeading"
          >
            <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.home.createOrEditLabel">
              Create or edit a PackageSubscriptionDiscount
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="package-subscription-discount-id"
                  label={translate('tinyMedicRestApp.packageSubscriptionDiscount.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.discount')}
                id="package-subscription-discount-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <UncontrolledTooltip target="discountLabel">
                <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.help.discount" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.actualDiscount')}
                id="package-subscription-discount-actualDiscount"
                name="actualDiscount"
                data-cy="actualDiscount"
                type="text"
              />
              <UncontrolledTooltip target="actualDiscountLabel">
                <Translate contentKey="tinyMedicRestApp.packageSubscriptionDiscount.help.actualDiscount" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.billNumber')}
                id="package-subscription-discount-billNumber"
                name="billNumber"
                data-cy="billNumber"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.billDate')}
                id="package-subscription-discount-billDate"
                name="billDate"
                data-cy="billDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.billType')}
                id="package-subscription-discount-billType"
                name="billType"
                data-cy="billType"
                type="select"
              >
                {billTypeValues.map(billType => (
                  <option value={billType} key={billType}>
                    {translate(`tinyMedicRestApp.BillType.${billType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.billId')}
                id="package-subscription-discount-billId"
                name="billId"
                data-cy="billId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.active')}
                id="package-subscription-discount-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.cursor')}
                id="package-subscription-discount-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.lastUpdated')}
                id="package-subscription-discount-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.microSec')}
                id="package-subscription-discount-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.timestampEntry')}
                id="package-subscription-discount-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.remarks')}
                id="package-subscription-discount-remarks"
                name="remarks"
                data-cy="remarks"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.exception')}
                id="package-subscription-discount-exception"
                name="exception"
                data-cy="exception"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.billOrigin')}
                id="package-subscription-discount-billOrigin"
                name="billOrigin"
                data-cy="billOrigin"
                type="select"
              >
                {billOriginValues.map(billOrigin => (
                  <option value={billOrigin} key={billOrigin}>
                    {translate(`tinyMedicRestApp.BillOrigin.${billOrigin}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="package-subscription-discount-usedBy"
                name="usedBy"
                data-cy="usedBy"
                label={translate('tinyMedicRestApp.packageSubscriptionDiscount.usedBy')}
                type="select"
              >
                <option value="" key="0" />
                {packageSubscribers
                  ? packageSubscribers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/package-subscription-discount"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PackageSubscriptionDiscountUpdate;
