import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getTenantIntegrationTemplates } from 'app/entities/tenant-integration-template/tenant-integration-template.reducer';
import { getEntities as getDataCategories } from 'app/entities/data-category/data-category.reducer';
import { getEntities as getTenantIntegrationChannels } from 'app/entities/tenant-integration-channel/tenant-integration-channel.reducer';
import { createEntity, getEntity, reset, updateEntity } from './tenant-integration-template-category.reducer';

export const TenantIntegrationTemplateCategoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tenantIntegrationTemplates = useAppSelector(state => state.tenantIntegrationTemplate.entities);
  const dataCategories = useAppSelector(state => state.dataCategory.entities);
  const tenantIntegrationChannels = useAppSelector(state => state.tenantIntegrationChannel.entities);
  const tenantIntegrationTemplateCategoryEntity = useAppSelector(state => state.tenantIntegrationTemplateCategory.entity);
  const loading = useAppSelector(state => state.tenantIntegrationTemplateCategory.loading);
  const updating = useAppSelector(state => state.tenantIntegrationTemplateCategory.updating);
  const updateSuccess = useAppSelector(state => state.tenantIntegrationTemplateCategory.updateSuccess);

  const handleClose = () => {
    navigate(`/tenant-integration-template-category${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTenantIntegrationTemplates({}));
    dispatch(getDataCategories({}));
    dispatch(getTenantIntegrationChannels({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }

    const entity = {
      ...tenantIntegrationTemplateCategoryEntity,
      ...values,
      template: tenantIntegrationTemplates.find(it => it.id.toString() === values.template?.toString()),
      category: dataCategories.find(it => it.id.toString() === values.category?.toString()),
      channel: tenantIntegrationChannels.find(it => it.id.toString() === values.channel?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...tenantIntegrationTemplateCategoryEntity,
          template: tenantIntegrationTemplateCategoryEntity?.template?.id,
          category: tenantIntegrationTemplateCategoryEntity?.category?.id,
          channel: tenantIntegrationTemplateCategoryEntity?.channel?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="tinyMedicRestApp.tenantIntegrationTemplateCategory.home.createOrEditLabel"
            data-cy="TenantIntegrationTemplateCategoryCreateUpdateHeading"
          >
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.home.createOrEditLabel">
              Create or edit a TenantIntegrationTemplateCategory
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="tenant-integration-template-category-id"
                  label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.active')}
                id="tenant-integration-template-category-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="activeLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.active" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.cursor')}
                id="tenant-integration-template-category-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <UncontrolledTooltip target="cursorLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.cursor" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.lastUpdated')}
                id="tenant-integration-template-category-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
              />
              <UncontrolledTooltip target="lastUpdatedLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.lastUpdated" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.microSec')}
                id="tenant-integration-template-category-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <UncontrolledTooltip target="microSecLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.microSec" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.timestampEntry')}
                id="tenant-integration-template-category-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
              />
              <UncontrolledTooltip target="timestampEntryLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.timestampEntry" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.tenantId')}
                id="tenant-integration-template-category-tenantId"
                name="tenantId"
                data-cy="tenantId"
                type="text"
              />
              <UncontrolledTooltip target="tenantIdLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.tenantId" />
              </UncontrolledTooltip>
              <ValidatedField
                id="tenant-integration-template-category-template"
                name="template"
                data-cy="template"
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.template')}
                type="select"
              >
                <option value="" key="0" />
                {tenantIntegrationTemplates
                  ? tenantIntegrationTemplates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tenant-integration-template-category-category"
                name="category"
                data-cy="category"
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.category')}
                type="select"
              >
                <option value="" key="0" />
                {dataCategories
                  ? dataCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tenant-integration-template-category-channel"
                name="channel"
                data-cy="channel"
                label={translate('tinyMedicRestApp.tenantIntegrationTemplateCategory.channel')}
                type="select"
              >
                <option value="" key="0" />
                {tenantIntegrationChannels
                  ? tenantIntegrationChannels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/tenant-integration-template-category"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TenantIntegrationTemplateCategoryUpdate;
