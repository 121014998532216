import { ITenantIntegrationTemplate } from 'app/shared/model/tenant-integration-template.model';
import { IDataCategory } from 'app/shared/model/data-category.model';
import { ITenantIntegrationChannel } from 'app/shared/model/tenant-integration-channel.model';

export interface ITenantIntegrationTemplateCategory {
  id?: string;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  tenantId?: string | null;
  template?: ITenantIntegrationTemplate | null;
  category?: IDataCategory | null;
  channel?: ITenantIntegrationChannel | null;
}

export const defaultValue: Readonly<ITenantIntegrationTemplateCategory> = {
  active: false,
};
