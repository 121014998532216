import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TenantIntegrationTemplateCategory from './tenant-integration-template-category';
import TenantIntegrationTemplateCategoryDetail from './tenant-integration-template-category-detail';
import TenantIntegrationTemplateCategoryUpdate from './tenant-integration-template-category-update';
import TenantIntegrationTemplateCategoryDeleteDialog from './tenant-integration-template-category-delete-dialog';

const TenantIntegrationTemplateCategoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TenantIntegrationTemplateCategory />} />
    <Route path="new" element={<TenantIntegrationTemplateCategoryUpdate />} />
    <Route path=":id">
      <Route index element={<TenantIntegrationTemplateCategoryDetail />} />
      <Route path="edit" element={<TenantIntegrationTemplateCategoryUpdate />} />
      <Route path="delete" element={<TenantIntegrationTemplateCategoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TenantIntegrationTemplateCategoryRoutes;
