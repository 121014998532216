import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getIntegrationChannels } from 'app/entities/integration-channel/integration-channel.reducer';
import { getEntities as getTenantIntegrationEndpointAttributes } from 'app/entities/tenant-integration-endpoint-attribute/tenant-integration-endpoint-attribute.reducer';
import { getEntities as getTenantIntegrationTemplates } from 'app/entities/tenant-integration-template/tenant-integration-template.reducer';
import { getEntities as getDataCategories } from 'app/entities/data-category/data-category.reducer';
import { createEntity, getEntity, reset, updateEntity } from './tenant-integration-channel.reducer';

export const TenantIntegrationChannelUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const integrationChannels = useAppSelector(state => state.integrationChannel.entities);
  const tenantIntegrationEndpointAttributes = useAppSelector(state => state.tenantIntegrationEndpointAttribute.entities);
  const tenantIntegrationTemplates = useAppSelector(state => state.tenantIntegrationTemplate.entities);
  const dataCategories = useAppSelector(state => state.dataCategory.entities);
  const tenantIntegrationChannelEntity = useAppSelector(state => state.tenantIntegrationChannel.entity);
  const loading = useAppSelector(state => state.tenantIntegrationChannel.loading);
  const updating = useAppSelector(state => state.tenantIntegrationChannel.updating);
  const updateSuccess = useAppSelector(state => state.tenantIntegrationChannel.updateSuccess);

  const handleClose = () => {
    navigate(`/tenant-integration-channel${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getIntegrationChannels({}));
    dispatch(getTenantIntegrationEndpointAttributes({}));
    dispatch(getTenantIntegrationTemplates({}));
    dispatch(getDataCategories({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }

    const entity = {
      ...tenantIntegrationChannelEntity,
      ...values,
      channel: integrationChannels.find(it => it.id.toString() === values.channel?.toString()),
      attribute: tenantIntegrationEndpointAttributes.find(it => it.id.toString() === values.attribute?.toString()),
      template: tenantIntegrationTemplates.find(it => it.id.toString() === values.template?.toString()),
      categories: mapIdList(values.categories),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...tenantIntegrationChannelEntity,
          channel: tenantIntegrationChannelEntity?.channel?.id,
          attribute: tenantIntegrationChannelEntity?.attribute?.id,
          template: tenantIntegrationChannelEntity?.template?.id,
          categories: tenantIntegrationChannelEntity?.categories?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.tenantIntegrationChannel.home.createOrEditLabel" data-cy="TenantIntegrationChannelCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.home.createOrEditLabel">
              Create or edit a TenantIntegrationChannel
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="tenant-integration-channel-id"
                  label={translate('tinyMedicRestApp.tenantIntegrationChannel.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.localName')}
                id="tenant-integration-channel-localName"
                name="localName"
                data-cy="localName"
                type="text"
              />
              <UncontrolledTooltip target="localNameLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.localName" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.localCode')}
                id="tenant-integration-channel-localCode"
                name="localCode"
                data-cy="localCode"
                type="text"
              />
              <UncontrolledTooltip target="localCodeLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.localCode" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.tenantId')}
                id="tenant-integration-channel-tenantId"
                name="tenantId"
                data-cy="tenantId"
                type="text"
              />
              <UncontrolledTooltip target="tenantIdLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.tenantId" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.tenantType')}
                id="tenant-integration-channel-tenantType"
                name="tenantType"
                data-cy="tenantType"
                type="text"
              />
              <UncontrolledTooltip target="tenantTypeLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.tenantType" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.active')}
                id="tenant-integration-channel-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="activeLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.active" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.cursor')}
                id="tenant-integration-channel-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <UncontrolledTooltip target="cursorLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.cursor" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.lastUpdated')}
                id="tenant-integration-channel-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
              />
              <UncontrolledTooltip target="lastUpdatedLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.lastUpdated" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.microSec')}
                id="tenant-integration-channel-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <UncontrolledTooltip target="microSecLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.microSec" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.timestampEntry')}
                id="tenant-integration-channel-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
              />
              <UncontrolledTooltip target="timestampEntryLabel">
                <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.timestampEntry" />
              </UncontrolledTooltip>
              <ValidatedField
                id="tenant-integration-channel-channel"
                name="channel"
                data-cy="channel"
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.channel')}
                type="select"
              >
                <option value="" key="0" />
                {integrationChannels
                  ? integrationChannels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tenant-integration-channel-attribute"
                name="attribute"
                data-cy="attribute"
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.attribute')}
                type="select"
              >
                <option value="" key="0" />
                {tenantIntegrationEndpointAttributes
                  ? tenantIntegrationEndpointAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tenant-integration-channel-template"
                name="template"
                data-cy="template"
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.template')}
                type="select"
              >
                <option value="" key="0" />
                {tenantIntegrationTemplates
                  ? tenantIntegrationTemplates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.tenantIntegrationChannel.category')}
                id="tenant-integration-channel-category"
                data-cy="category"
                type="select"
                multiple
                name="categories"
              >
                <option value="" key="0" />
                {dataCategories
                  ? dataCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/tenant-integration-channel" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TenantIntegrationChannelUpdate;
