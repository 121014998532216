import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './tenant-integration-channel.reducer';

export const TenantIntegrationChannelDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tenantIntegrationChannelEntity = useAppSelector(state => state.tenantIntegrationChannel.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tenantIntegrationChannelDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.detail.title">TenantIntegrationChannel</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.id">Id</Translate>
            </span>
            <UncontrolledTooltip target="id">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.id" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.id}</dd>
          <dt>
            <span id="localName">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.localName">Local Name</Translate>
            </span>
            <UncontrolledTooltip target="localName">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.localName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.localName}</dd>
          <dt>
            <span id="localCode">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.localCode">Local Code</Translate>
            </span>
            <UncontrolledTooltip target="localCode">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.localCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.localCode}</dd>
          <dt>
            <span id="tenantId">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.tenantId">Tenant Id</Translate>
            </span>
            <UncontrolledTooltip target="tenantId">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.tenantId" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.tenantId}</dd>
          <dt>
            <span id="tenantType">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.tenantType">Tenant Type</Translate>
            </span>
            <UncontrolledTooltip target="tenantType">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.tenantType" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.tenantType}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.cursor">Cursor</Translate>
            </span>
            <UncontrolledTooltip target="cursor">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.cursor" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.lastUpdated">Last Updated</Translate>
            </span>
            <UncontrolledTooltip target="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.lastUpdated" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.microSec">Micro Sec</Translate>
            </span>
            <UncontrolledTooltip target="microSec">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.microSec" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.timestampEntry">Timestamp Entry</Translate>
            </span>
            <UncontrolledTooltip target="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.help.timestampEntry" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationChannelEntity.timestampEntry}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.channel">Channel</Translate>
          </dt>
          <dd>{tenantIntegrationChannelEntity.channel ? tenantIntegrationChannelEntity.channel.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.attribute">Attribute</Translate>
          </dt>
          <dd>{tenantIntegrationChannelEntity.attribute ? tenantIntegrationChannelEntity.attribute.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.template">Template</Translate>
          </dt>
          <dd>{tenantIntegrationChannelEntity.template ? tenantIntegrationChannelEntity.template.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationChannel.category">Category</Translate>
          </dt>
          <dd>
            {tenantIntegrationChannelEntity.categories
              ? tenantIntegrationChannelEntity.categories.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {tenantIntegrationChannelEntity.categories && i === tenantIntegrationChannelEntity.categories.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/tenant-integration-channel" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tenant-integration-channel/${tenantIntegrationChannelEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TenantIntegrationChannelDetail;
