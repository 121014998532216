import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './package-medicine.reducer';

export const PackageMedicineDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packageMedicineEntity = useAppSelector(state => state.packageMedicine.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packageMedicineDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.packageMedicine.detail.title">PackageMedicine</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.id">Id</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.active">Active</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.cursor}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.lastupdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.microSec}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.timestampentry}</dd>
          <dt>
            <span id="quantity">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.quantity">Quantity</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.quantity}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.discount}</dd>
          <dt>
            <span id="discountType">
              <Translate contentKey="tinyMedicRestApp.packageMedicine.discountType">Discount Type</Translate>
            </span>
          </dt>
          <dd>{packageMedicineEntity.discountType}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageMedicine.scheme">Scheme</Translate>
          </dt>
          <dd>{packageMedicineEntity.scheme ? packageMedicineEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageMedicine.medicine">Medicine</Translate>
          </dt>
          <dd>{packageMedicineEntity.medicine ? packageMedicineEntity.medicine.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageMedicine.consumable">Consumable</Translate>
          </dt>
          <dd>{packageMedicineEntity.consumable ? packageMedicineEntity.consumable.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageMedicine.implant">Implant</Translate>
          </dt>
          <dd>{packageMedicineEntity.implant ? packageMedicineEntity.implant.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/package-medicine" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/package-medicine/${packageMedicineEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackageMedicineDetail;
