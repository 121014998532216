import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './tenant-integration-template-category.reducer';

export const TenantIntegrationTemplateCategoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tenantIntegrationTemplateCategoryEntity = useAppSelector(state => state.tenantIntegrationTemplateCategory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tenantIntegrationTemplateCategoryDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.detail.title">
            TenantIntegrationTemplateCategory
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.id">Id</Translate>
            </span>
            <UncontrolledTooltip target="id">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.id" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.cursor">Cursor</Translate>
            </span>
            <UncontrolledTooltip target="cursor">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.cursor" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.lastUpdated">Last Updated</Translate>
            </span>
            <UncontrolledTooltip target="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.lastUpdated" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.microSec">Micro Sec</Translate>
            </span>
            <UncontrolledTooltip target="microSec">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.microSec" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.timestampEntry">Timestamp Entry</Translate>
            </span>
            <UncontrolledTooltip target="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.timestampEntry" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.timestampEntry}</dd>
          <dt>
            <span id="tenantId">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.tenantId">Tenant Id</Translate>
            </span>
            <UncontrolledTooltip target="tenantId">
              <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.help.tenantId" />
            </UncontrolledTooltip>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.tenantId}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.template">Template</Translate>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.template ? tenantIntegrationTemplateCategoryEntity.template.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.category">Category</Translate>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.category ? tenantIntegrationTemplateCategoryEntity.category.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.tenantIntegrationTemplateCategory.channel">Channel</Translate>
          </dt>
          <dd>{tenantIntegrationTemplateCategoryEntity.channel ? tenantIntegrationTemplateCategoryEntity.channel.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/tenant-integration-template-category" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/tenant-integration-template-category/${tenantIntegrationTemplateCategoryEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TenantIntegrationTemplateCategoryDetail;
