import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TenantIntegrationChannel from './tenant-integration-channel';
import TenantIntegrationChannelDetail from './tenant-integration-channel-detail';
import TenantIntegrationChannelUpdate from './tenant-integration-channel-update';
import TenantIntegrationChannelDeleteDialog from './tenant-integration-channel-delete-dialog';

const TenantIntegrationChannelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TenantIntegrationChannel />} />
    <Route path="new" element={<TenantIntegrationChannelUpdate />} />
    <Route path=":id">
      <Route index element={<TenantIntegrationChannelDetail />} />
      <Route path="edit" element={<TenantIntegrationChannelUpdate />} />
      <Route path="delete" element={<TenantIntegrationChannelDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TenantIntegrationChannelRoutes;
